import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 26px;
  background: var(--white);
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  margin-top: 24px;

  > div:last-child {
    display: flex;
    margin-right: 0;
    margin-top: auto;
    /* margin-left: auto; */
    gap: 16px;
    margin-top: 24px;
  }
  @media (max-width: 600px) {
    > div:last-child {
      width: 100%;
      margin-top: 24px;

      > button {
        width: 100%;
      }
    }
  }
`

export const Label = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: var(--dark-gray);
`

export const Description = styled.div`
  font-size: 12px;
  color: var(--dark-gray);
  margin-bottom: 10px;
`

interface ProgressBarProps {
  percentage: number
}

export const ProgressBar = styled.div<ProgressBarProps>`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  background: var(--light-gray);
  height: 5px;
  border-radius: 5px;
  overflow: hidden;

  > div {
    border-radius: 5px;
    transition: 0.2s ease;
    height: 100%;
    width: ${props => `${props.percentage}%`};
    background: var(--primary);
  }
`

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  bottom: 24px;
  width: 32px;
  height: 32px;
  background: var(--medium-gray);
  color: var(--dark-gray);
  border-radius: 32px;
  cursor: pointer;
  transition: 0.2s ease;
  box-shadow: var(--box-shadow);
  z-index: 1;

  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
  }
`
