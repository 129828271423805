import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 980px) {
    flex-direction: column-reverse;
  }
`

interface WrapperProps {
  hasCta?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  @media (max-width: 600px) {
    ${props => props.hasCta && 'padding-bottom: 33px;'}
  }
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  overflow-y: auto;
  padding: 64px;
  background: var(--background);

  @media (max-width: 600px) {
    padding: 32px;
  }
`

export const LeftMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  min-width: 100px;
  background: var(--white);
  box-shadow: var(--box-shadow);
  padding: 64px 0;
  z-index: 1;

  @media (max-width: 980px) {
    height: 70px;
    flex-direction: row;
    padding: 12px 32px;
    z-index: 2;
  }
`

export const LogoContainer = styled.div`
  @media (max-width: 980px) {
    display: none;
  }
`

interface ItemsContainerProps {
  isAdmin?: boolean
}

export const ItemsContainer = styled.div<ItemsContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => (props.isAdmin ? 36 : 64)}px;

  @media (max-width: 980px) {
    flex: 1;
    flex-direction: row;
    gap: 0;
    justify-content: space-around;
  }
`

interface MenuItemProps {
  active?: boolean
}
export const MenuItem = styled.a<MenuItemProps>`
  color: ${props => (props.active ? 'var(--primary)' : 'var(--medium-gray)')};
  cursor: pointer;
`

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  > a img {
    width: 42px;
    height: 42px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: var(--box-shadow-right);
  }

  @media (max-width: 980px) {
    display: none;
  }
`

export const ResponsiveMenu = styled.div`
  display: none;
  padding: 0 64px;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  box-shadow: var(--box-shadow);
  z-index: 1;

  > div {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  > div a {
    display: flex;
    align-items: center;
  }

  > div a img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: var(--box-shadow-right);
  }

  @media (max-width: 980px) {
    display: flex;
  }

  @media (max-width: 600px) {
    padding: 0 32px;
  }
`
