import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid var(--medium-gray);
  padding-bottom: 32px;
  margin-bottom: 32px;
  gap: 10px;

  > button {
    position: absolute;
    right: 0;
    top: 0;

    @media (max-width: 600px) {
      position: fixed;
      right: 0;
      left: 0;
      z-index: 1;
      top: inherit;
      bottom: 86px;
      width: calc(100% - 32px);
      margin: auto;
      box-shadow: 0px 16px 16px 32px #ffffff;
    }
  }
`

export const Title = styled.h1`
  color: var(--black);
`

export const Slug = styled.span`
  color: var(--dark-gray);

  > span > a {
    text-decoration: none;
    transition: 0.2s ease;
    text-transform: capitalize;

    :hover {
      text-decoration: underline;
    }
  }
  > span:last-child > a {
    color: var(--primary);
  }
`
