import React from 'react'

import { Container } from './styles'

import { Circles } from '@agney/react-loading'

const Loading: React.FC = () => {
  return (
    <Container>
      <div>
        <Circles />
      </div>
    </Container>
  )
}

export default Loading
