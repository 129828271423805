import moment from 'moment'

const moneyFormatter = (value: string): string => {
  const v = Math.round(parseFloat(value) * 100) / 100
  return v.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

const phoneFormatter = (phone: string): string => {
  if (!phone.substring(phone.length - 1).match(/^[a-zA-Z]+$/)) {
    const cleaned = ('' + phone).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    const match2 = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)
    if (match2) {
      return '(' + match2[1] + ') ' + match2[2] + '-' + match2[3]
    }
    return phone.substring(0, 15)
  } else {
    return ''
  }
}

const cpfFormatter = (cpf: string): string => {
  // retira os caracteres indesejados...
  cpf = cpf.replace(/[^\d]/g, '')

  // realizar a formatação...
  return cpf
    .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    .substring(0, 14)
}

const checkArrayIsSame = (array1: string[], array2: string[]): boolean => {
  const is_same =
    array1.length === array2.length &&
    array1.every(function (element, index) {
      return element === array2[index]
    })

  return is_same
}

const firstToUpper = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

const checkEmail = (mail: string): boolean => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true
  }
  return false
}

const checkDate = (date: string): boolean => {
  if (date.length < 5) return false
  const actualDate = moment()
  return !!moment(date, 'MM/YY').isAfter(actualDate, 'month')
}

const urlFormatter = (url: string): string | null => {
  if (
    url.match(
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
    )
  ) {
    if (url.includes('https://') || url.includes('http://')) {
      return url
    } else {
      return 'https://' + url
    }
  } else {
    return null
  }
}

const getLuma = (hex: string): string => {
  const c = hex.substring(1) // strip #
  const rgb = parseInt(c, 16) // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff // extract red
  const g = (rgb >> 8) & 0xff // extract green
  const b = (rgb >> 0) & 0xff // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

  if (luma < 150) {
    return 'white'
  } else {
    return 'var(--black)'
  }
}

const generateDropDownByStringArray = (
  arr: string[]
): { text: string; value: string }[] => {
  const newArr: { text: string; value: string }[] = []
  arr.map(item => {
    newArr.push({
      text: item,
      value: item
    })
    return null
  })
  return newArr
}

const hasDifference = (arr1: any[], arr2: any[], prop: string): boolean => {
  let diff = false
  arr1.map((item, i) => {
    if (arr2[i]) {
      if (item[prop] !== arr2[i][prop]) {
        diff = true
      }
    } else {
      diff = true
    }
    return null
  })
  return diff
}

interface Question {
  id: string;
  text: string;
  answers: { text: string; isCorrect: boolean }[];
  feedback: string;
  reference: string;
}

type DiffProps = { [key: string]: any };

function getDifferentObjects(arr1: Question[], arr2: Question[]): Partial<Question>[] {
  let result: Partial<Question>[] = [];
  let ids = new Set(arr1.map(obj => obj.id));
  arr2.forEach(obj => {
    if (!('id' in obj) || !ids.has(obj.id)) {
      result.push(obj);
      return;
    }
    let match = arr1.find(o => o.id === obj.id);
    let diffAnswers = obj.answers.filter(
      // @ts-ignore
      (answer, index) => JSON.stringify(answer) !== JSON.stringify(match.answers[index])
      );
      let diffProps: DiffProps = {};
      for (let prop in obj) {
      // @ts-ignore
      if (prop !== 'id' && prop !== 'answers' && obj[prop] !== match[prop]) {
        // @ts-ignore
        diffProps[prop] = obj[prop];
      }
    }
    if (diffAnswers.length > 0 || Object.keys(diffProps).length > 0) {
      result.push({ id: obj.id, answers: obj.answers, ...diffProps });
    }
  });
  return result;
}





const formatDate = (date: string): string => {
  return moment(date).format('DD/MM/YYYY HH:mm')
}

export {
  moneyFormatter,
  phoneFormatter,
  checkArrayIsSame,
  cpfFormatter,
  firstToUpper,
  checkEmail,
  checkDate,
  urlFormatter,
  getLuma,
  generateDropDownByStringArray,
  hasDifference,
  formatDate,
  getDifferentObjects
}
