import React from 'react'

import { Container, Content } from './styles'

import LoadingAnimation from '../../../assets/animations/notFound.json'
import Lottie from 'react-lottie'

const defaultOptionsAnimation = {
  loop: false,
  autoPlay: true,
  animationData: LoadingAnimation,
  renderSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const NotFound: React.FC = () => {
  return (
    <Container>
      <Content>
        <Lottie options={defaultOptionsAnimation} height={250} width={250} />
        <h1>Página não encontrada</h1>
        <span>
          Procuramos em todos os nossos produtos e não conseguimos encontrar
          essa página.
        </span>
      </Content>
    </Container>
  )
}

export default NotFound
