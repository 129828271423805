import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 18px;
  border-radius: 5px;
  border: 1px solid var(--light-gray);

  >h3 {
    margin-bottom: 8px;
    color: var(--white);
    background: var(--primary);
    width: fit-content;
    padding: 4px 16px;
    font-size: 16px;
    border-radius: 30px;
  }

  > button:last-child {
    margin: auto;
  }
`
