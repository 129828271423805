import { collection, getDocs } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useGeneral } from '../../../contexts/GeneralContext'
import { firestore, getAdminList, getDocumentList } from '../../../lib/firebase'
import Frame from '../../Frame'
import Pagination from '../../Pagination'

import * as S from './styles'

const Admins: React.FC = () => {
  const [list, setList] = useState<any[]>([])
  const [actualPage, setActualPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const pageSize = 10
  const { isLoading, setIsLoading, alertError, setShowUserModal } = useGeneral()

  const handleGetCompanies = async (page?: number) => {
    setIsLoading(true)
    try {
      // const { users, pages } = await getAllUsers(page || undefined)
      
      const res = await getAdminList()
      const list = res.data as Array<any>
      const totalCount = list.length
      const pageCount = Math.ceil(totalCount / pageSize)
      
      const newActual = (actualPage - 1) * pageSize
      setList(list.slice(newActual, newActual + pageSize))
      setPageCount(pageCount)
    } catch (error) {
      console.log(error)
      alertError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    handleGetCompanies()
  }, [])

  const handlePagination = async (fn: 'next' | 'back' | number) => {
    let newActualPage = actualPage

    if (fn === 'next') {
      newActualPage += 1
    } else if (fn === 'back') {
      newActualPage -= 1
    } else {
      newActualPage = fn
    }

    const { documentList } = await getDocumentList('users')

    const newActual = (newActualPage - 1) * pageSize
    setList(documentList.slice(newActual, newActual + pageSize))
    setActualPage(newActualPage)
  }

  return isLoading ? null : (
    <Frame>
      <S.Container>
        <S.TableContainer>
          {list?.map((item, i) => {
            return (
              <S.CompanyItem key={i}>
                <S.CompanyImage src="https://i.imgur.com/RpO4IrB.png" />
                <span>{item.email}</span>
              </S.CompanyItem>
            )
          })}
        </S.TableContainer>
        <Pagination
          currentPage={actualPage}
          totalPages={pageCount}
          fn={handlePagination}
        />
      </S.Container>
    </Frame>
  )
}

export default Admins
