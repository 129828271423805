import React  from 'react'
import { IoClose } from 'react-icons/io5'
import * as S from './styles'
import { useGeneral } from '../../../contexts/GeneralContext'
import { deleteDocumentFirebase } from '../../../lib/firebase'

interface DeleteQuestionProps {
  data?: any
  setDeleteQuestion?: any
}

const DeleteQuestion: React.FC<DeleteQuestionProps> = ({
  data,
  setDeleteQuestion
}) => {
  const { setIsLoading, alertError } = useGeneral()

  const handleDelete = async () => {
    setIsLoading(true)
    try {
      await deleteDocumentFirebase('questions', data)
    } catch (error) {
      alertError(error)
    }
    setIsLoading(false)
    setDeleteQuestion(undefined)
  }

  return (
    <S.Container>
      <div onClick={() => setDeleteQuestion(undefined)}></div>
      <S.Content>
        <S.Header>
          <h1> </h1>
          <button onClick={() => setDeleteQuestion(undefined)}>
            <IoClose size={24} />
          </button>
        </S.Header>
        <S.Body>
          <h1>Você tem certeza que deseja apagar esta pergunta?</h1>
          <span>
            Depois de apagar, você não poderá reaver a pergunta e as respostas
            em questão...
          </span>
          <S.Divisor />
          <div style={{display: 'flex', width: '100%', gap: 16}}>
            <S.Button
              secondary
              onClick={handleDelete}
            >
              Apagar
            </S.Button>
            <S.Button
              onClick={() => {
                setDeleteQuestion(undefined)
              }}
            >
              Cancelar
            </S.Button>
          </div>
        </S.Body>
      </S.Content>
    </S.Container>
  )
}

export default DeleteQuestion
