import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5'
import * as S from './styles'
import { useGeneral } from '../../../contexts/GeneralContext'
import { auth, createAdmin } from '../../../lib/firebase'
import InputModal from '../InputModal'
import { sendPasswordResetEmail } from 'firebase/auth'

interface CreateAdminProps {
  data?: any
  setCreateAdmin?: any
}

const CreateAdmin: React.FC<CreateAdminProps> = ({ data, setCreateAdmin }) => {
  const { setIsLoading, alertError } = useGeneral()
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [pass, setPass] = useState('')

  const handleCreate = async () => {
    setIsLoading(true)
    try {
      await createAdmin({
        email,
        displayName: name,
        password: pass
      })
      await sendPasswordResetEmail(auth, email)
    } catch (error) {
      alertError(error)
    }
    setIsLoading(false)
    window.location.reload()
  }

  return (
    <S.Container>
      <div onClick={() => setCreateAdmin(undefined)}></div>
      <S.Content>
        <S.Header>
          <h1>Criar novo admin</h1>
          <button onClick={() => setCreateAdmin(undefined)}>
            <IoClose size={24} />
          </button>
        </S.Header>
        <S.Body>
          <span style={{ textAlign: 'left' }}>
            Depois de apagar, você não poderá reaver a pergunta e as respostas
            em questão...
          </span>
          <InputModal
            label="Nome"
            placeholder="Digite o nome..."
            value={name}
            setValue={setName}
            isEditable={true}
          />
          <InputModal
            label="E-mail"
            placeholder="Digite o e-mail..."
            value={email}
            setValue={setEmail}
            isEditable={true}
          />
          <InputModal
            label="Senha"
            placeholder="Digite a senha..."
            value={pass}
            setValue={setPass}
            isEditable={true}
          />
          <S.Divisor />
          <div style={{ display: 'flex', width: '100%', gap: 16 }}>
            <S.Button
              secondary
              onClick={() => {
                setCreateAdmin(undefined)
              }}
            >
              Cancelar
            </S.Button>
            <S.Button onClick={handleCreate}>Criar</S.Button>
          </div>
        </S.Body>
      </S.Content>
    </S.Container>
  )
}

export default CreateAdmin
