/* eslint-disable indent */
/* eslint-disable multiline-ternary */
import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../../Button'

import * as C from './styles'
import * as S from '../styles'
import { getDifferentObjects } from '../../../../../lib/helpers'
import { useGeneral } from '../../../../../contexts/GeneralContext'
import { AiFillDelete, AiFillEdit } from 'react-icons/ai'
import { createQuestion, getQuestionsList, updateQuestion } from '../../../../../lib/firebase'
import Form from './Form'

interface Step2Props {
  goalId: string
}

const initialData = {
  text: '',
  answers: [
    {
      text: '',
      isCorrect: true
    },
    {
      text: '',
      isCorrect: false
    },
    {
      text: '',
      isCorrect: false
    },
    {
      text: '',
      isCorrect: false
    }
  ],
  feedback: '',
  reference: ''
}

const Step2: React.FC<Step2Props> = ({ goalId }) => {
  const { isLoading, alertError, setShowDeleteQuestion, setIsLoading } =
    useGeneral()
  const [newQuestion, setNewQuestion] = useState<{
    text: string
    answers: {
      text: string
      isCorrect: boolean
    }[]
    feedback: string
    reference: string
  }>(initialData)
  const [questions, setQuestions] = useState<any[]>()
  const [openedEdit, setOpenedEdit] = useState<number | undefined>()
  const originalData = useRef<any>()

  const handleFillData = async () => {
    try {
      const { documentList } = await getQuestionsList(goalId)
      originalData.current = documentList
      setQuestions(documentList)
    } catch (error) {
      alertError(error)
    }
  }

  const handleSaveQuestions = async () => {
    setIsLoading(true)
    try {
      // Do something
      if (questions) {
        const diference = getDifferentObjects(originalData.current, questions)
        const create = diference.filter(e => e.id === undefined)
        const update = diference.filter(e => e.id !== undefined)

        for (let index = 0; index < update.length; index++) {
          await updateQuestion(update[index])
        }

        for (let index = 0; index < create.length; index++) {
          await createQuestion(create[index], goalId)
        }

        console.log(diference)
      }
    } catch (error) {
      alertError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (!originalData.current) {
      handleFillData()
    }
  }, [goalId])

  return isLoading ? null : (
    <S.Content>
      <S.Column>
        <S.Label>Perguntas</S.Label>
        <S.Description>
          Cadastre suas perguntas e preencha as possíveis respostas
        </S.Description>
      </S.Column>

      <C.PersonaList>
        {questions?.map((question, i) => {
          return (
            <div key={i} style={{display: 'flex', flexDirection: 'column', gap: 16}}> 
              <C.PersonaItem>
                <div>
                  <span>{question.text}</span>
                </div>
                <div></div>
                <div style={{ marginLeft: 16 }}>
                  <AiFillEdit
                    style={{ marginRight: 8 }}
                    size={20}
                    onClick={() => {
                      setOpenedEdit(openedEdit === i ? undefined : i)
                    }}
                  />
                  <AiFillDelete
                    size={20}
                    onClick={() => {
                      if (question.id) {
                        setShowDeleteQuestion(question.id)
                      } else {
                        const copy = [...questions]
                        delete copy[i]
                        setQuestions(copy)
                      }
                    }}
                  />
                </div>
              </C.PersonaItem>
              {openedEdit === i && (
                <Form
                  isEdit
                  finish={() => setOpenedEdit(undefined)}
                  data={question}
                  setData={(value: any) => {
                    const newData = [...questions]
                    newData[i] = value
                    setQuestions(newData)
                  }}
                />
              )}
            </div>
          )
        })}
      </C.PersonaList>

      <Form data={newQuestion} setData={setNewQuestion} />
      <Button
        colorScheme={2}
        fullWidth
        disabled={
          !newQuestion.text ||
          !newQuestion.reference ||
          !newQuestion.feedback ||
          !newQuestion.answers[0].text ||
          !newQuestion.answers[1].text ||
          !newQuestion.answers[2].text ||
          !newQuestion.answers[3].text
        }
        onClick={() => {
          if (questions) {
            const newData = { ...newQuestion }
            const newQuestionsArray = [...questions]
            newQuestionsArray.push(newData)
            setQuestions(newQuestionsArray)
            setNewQuestion(initialData)
          }
        }}
      >
        Adicionar nova pergunta
      </Button>
      <Button
        colorScheme={2}
        onClick={handleSaveQuestions}
        style={{ marginLeft: 'auto' }}
      >
        Salvar no banco de dados
      </Button>
    </S.Content>
  )
}

export default Step2
