import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

import * as S from './styles'

interface PaginationProps {
  totalPages: number
  currentPage: number
  fn: (fn: 'back' | 'next' | number) => void
}

const handleVerifyAction = (
  fn: 'back' | 'next' | number,
  totalPages: number,
  currentePage: number
): boolean => {
  if (fn === 'back' && currentePage === 1) {
    return false
  }
  if (fn === 'next' && currentePage === totalPages) {
    return false
  }
  return true
}

interface NavigationButtonProps {
  totalPages: number
  currentPage: number
  action: 'back' | 'next'
  fn: (fn: 'back' | 'next' | number) => void
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  totalPages,
  currentPage,
  action,
  fn
}) => {
  const hasAction = handleVerifyAction(action, totalPages, currentPage)
  return (
    <button
      style={{
        opacity: hasAction ? 1 : 0.5,
        cursor: hasAction ? 'pointer' : 'default'
      }}
      onClick={() => {
        if (hasAction) {
          fn(action)
        }
      }}
    >
      {action === 'back' ? (
        <FaChevronLeft size={20} />
      ) : (
        <FaChevronRight size={20} />
      )}
    </button>
  )
}
const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  fn
}) => {
  return (
    <S.Container>
      {totalPages > 0 && (
        <div>
          <NavigationButton
            action="back"
            currentPage={currentPage}
            totalPages={totalPages}
            fn={fn}
          />
          {currentPage >= 3 && <S.Button onClick={() => fn(1)}>1</S.Button>}
          {currentPage >= 4 && <span>...</span>}
          {currentPage > 1 && (
            <S.Button onClick={() => fn(currentPage - 1)}>
              {currentPage - 1}
            </S.Button>
          )}
          <S.Button active>{currentPage}</S.Button>
          {currentPage + 1 <= totalPages && (
            <S.Button onClick={() => fn(currentPage + 1)}>
              {currentPage + 1}
            </S.Button>
          )}
          {currentPage <= totalPages - 3 && totalPages >= 4 && <span>...</span>}
          {currentPage < totalPages - 1 && (
            <S.Button onClick={() => fn(totalPages)}>{totalPages}</S.Button>
          )}
          <NavigationButton
            action="next"
            currentPage={currentPage}
            totalPages={totalPages}
            fn={fn}
          />
        </div>
      )}
    </S.Container>
  )
}

export default Pagination
