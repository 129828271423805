import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @media (max-width: 980px) {
    width: calc(100% + 64px);
  }

  @media (max-width: 600px) {
    width: calc(100% + 32px);
  }
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 980px) {
    overflow: auto;
    padding-right: 64px;
    padding-bottom: 24px;
    margin-left: -64px;
    padding-left: 64px;
  }

  @media (max-width: 600px) {
    padding-right: 32px;
    margin-left: -32px;
    padding-left: 32px;
  }
`

export const CompanyItem = styled.div`
  display: flex;
  align-items: center;
  background: var(--white);
  box-shadow: var(--box-shadow);
  padding: 16px 26px;
  border-radius: 5px;
  color: var(--dark-gray);
  transition: 0.2s ease;
  min-width: 780px;

  > span:nth-child(2) {
    font-weight: bold;
    width: fit-content;
    min-width: 160px;
  }

  > div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    gap: 16px;
    font-size: 14px;

    @media (max-width: 980px) {
      justify-content: flex-start;
      padding-left: 16px;
      > span {
        min-width: fit-content;
      }
    }
  }

  > button {
    margin-left: 24px;
    color: var(--dark-gray);
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;

    &:last-child {
      color: var(--primary);
    }
  }

  @media (max-width: 980px) {
    min-width: 900px;
  }
`

export const CompanyImage = styled.img`
  margin-right: 26px;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  box-shadow: var(--box-shadow);
`
