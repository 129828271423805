import { FiLayers, FiShield, FiUsers } from 'react-icons/fi'

export const iconSize = 22

interface PageProps {
  label: string
  url: string
  slug?: any
  cta?: {
    title: string
    action: any
  }
}

export const menuItems = [
  {
    label: 'Usuários',
    url: '/',
    icon: <FiUsers size={iconSize} />
  },
  {
    label: 'Metas',
    url: '/metas',
    icon: <FiLayers size={iconSize} />
  },
  {
    label: 'Administradores',
    url: '/admins',
    icon: <FiShield size={iconSize} />
  }
]

export const pages = [
  {
    label: 'Usuários',
    url: '/',
    slug: '/dashboard'
  },
  {
    label: 'Metas',
    url: '/metas'
  },
  {
    label: 'Editar meta - :goalName',
    url: '/metas/:id'
  },
  {
    label: 'Administradores',
    url: '/admins',
    cta: {
      title: 'Add admin',
      action: (setModalOpened: any): any => {
        setModalOpened(true)
      }
    }
  }
]

export const formmatSlug = (slug: string): string => {
  return slug.replaceAll('-', ' ')
}

export const getUrlFromSlug = (
  items: string[],
  actualIndex: number
): string => {
  let slug = '/'
  for (let index = 0; index <= actualIndex; index++) {
    slug += items[index] + '/'
  }
  if (slug.substring(slug.length - 1)) {
    slug = slug.slice(0, -1)
  }
  return slug
}

export const getPage = (locationPathName: string): PageProps | undefined => {
  const n = locationPathName.indexOf('?')
  let path = locationPathName.substring(
    0,
    n !== -1 ? n : locationPathName.length
  )
  if (path.length > 1 && path[path.length - 1] === '/') path = path.slice(0, -1)

  const page = pages.find(page => {
    const pageParts = page.url.split('/')
    const pathParts = path.split('/')
    if (pageParts.length !== pathParts.length) return false
    let isPage = false

    pathParts.map((item, i) => {
      if (pageParts[i].includes(':')) {
        return null
      }
      if (item === pageParts[i]) {
        isPage = true
      } else {
        isPage = false
      }
      return null
    })

    return isPage
  })

  return page
}

export const getSlug = (page: PageProps | undefined): string[] => {
  const slugPath = page?.slug ? page.slug.split('/') : page?.url.split('/')
  slugPath?.shift()

  return slugPath
}
