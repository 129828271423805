import React from 'react'

import * as S from './styles'

interface InputModalProps {
  label: string
  value?: string
  setValue?: any
  isEditable?: boolean
  placeholder?: string
}

const InputModal: React.FC<InputModalProps> = ({ label, value, setValue, isEditable, placeholder }) => {
  return (isEditable || value) ? (
    <S.Container>
      <S.Label>{label}</S.Label>
      <S.Input placeholder={placeholder} value={value} onChange={(e) => {
        setValue && setValue(e.target.value)
      }} readOnly={!isEditable} />
    </S.Container>
  ) : null
}

export default InputModal
