// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocs,
  getFirestore,
  query,
  QuerySnapshot,
  updateDoc,
  where
} from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: 'AIzaSyAFPRNxHSlECheAqjnRWLx1DEZ_fEXqknQ',
  authDomain: 'pediatrix-c8e25.firebaseapp.com',
  projectId: 'pediatrix-c8e25',
  storageBucket: 'pediatrix-c8e25.appspot.com',
  messagingSenderId: '527595587801',
  appId: '1:527595587801:web:05221376a9b2701da116c8'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const functions = getFunctions(app)

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app)
export const firestore = getFirestore(app)

export const getDocumentList = async (collectionName: string) => {
  const docRef = collection(firestore, collectionName)

  const querySnapshot: QuerySnapshot = await getDocs(docRef)
  const documentList = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }))

  return {
    documentList
  }
}

export const createAdmin = httpsCallable(functions, 'createUser')

export const getAdminList = httpsCallable(functions, 'listUsers')

export const getQuestionsList = async (goal: string) => {
  const docRef = collection(firestore, 'questions')
  const goalRef = doc(firestore, 'goals', goal)
  const q = query(docRef, where('goal', '==', goalRef))
  // const q = query(docRef, where('goal', '==', `projects/pediatrixc8e25/databases/(default)/documents/goals/${goal}`))

  const querySnapshot: QuerySnapshot = await getDocs(q)
  const documentList = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }))

  return {
    documentList
  }
}

export const getUniqueDocument = async (
  collectionName: string,
  documentName: string
) => {
  const docRef = doc(firestore, collectionName, documentName)

  const docSnapshot: DocumentSnapshot = await getDoc(docRef)
  const documentData = docSnapshot.data()
  return {
    id: docSnapshot.id,
    ...documentData
  }
}

export const deleteDocumentFirebase = async (
  collectionName: string,
  documentName: string
) => {
  const docRef = doc(firestore, collectionName, documentName)

  await deleteDoc(docRef)
}

// export const updateGoals = async () => {
//   const docRef = collection(firestore, 'questions')

//   const querySnapshot: QuerySnapshot = await getDocs(docRef)
//   const documentList = querySnapshot.docs.map(async (doc) => {
//     const goal = doc.data().goal
//     const newGoal = goal
//     await updateDoc(doc.ref, {
//       goal: newGoal
//     })
//   })
//   console.log(documentList)
// }

export const updateQuestion = async (data: any) => {
  const docRef = doc(firestore, 'questions', data.id)
  delete data.id

  await updateDoc(docRef, {
    ...data
  })
}

export const createQuestion = async (data: any, goal: string) => {
  const docRef = collection(firestore, 'questions')
  const goalRef = doc(firestore, 'goals', goal)

  await addDoc(docRef, {
    ...data,
    goal: goalRef
  })
}

export default app
