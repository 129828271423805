import styled from 'styled-components'

export const PersonaList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const PersonaItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 26px;
  border-radius: 5px;
  background: rgba(var(--primary-rgb), 0.05);

  > div {
    display: flex;
    align-items: center;
  }

  svg {
    color: rgba(var(--primary-rgb), 0.5);
  }

  > div:first-child {
    gap: 26px;
    font-weight: bold;
    color: var(--dark-gray);
  }

  > div:nth-child(2) {
    color: var(--primary);
    font-size: 13px;
    gap: 22px;

    @media (max-width: 1200px) {
      min-width: 100%;
      order: 3;
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      flex-wrap: wrap;
    }

    @media (max-width: 600px) {
      margin-top: 8px;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      > span {
        width: 100%;
        padding-top: 8px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);

        :first-child {
          border-top: none;
        }
      }
    }
  }

  > div:last-child {
    color: var(--medium-gray);
    gap: 12px;
    > svg {
      cursor: pointer;
    }
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`
