import React, { useEffect, useState } from 'react'
import { useGeneral } from '../../../contexts/GeneralContext'
import { getDocumentList } from '../../../lib/firebase'
import Frame from '../../Frame'

import * as S from './styles'

const Goals: React.FC = () => {
  const [list, setList] = useState<any[]>([])
  const { isLoading, setIsLoading, alertError } = useGeneral()

  const handleGetCompanies = async (page?: number) => {
    setIsLoading(true)
    try {
      const { documentList } = await getDocumentList('goals')
      setList(documentList)
    } catch (error) {
      alertError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    handleGetCompanies()
  }, [])

  return isLoading ? null : (
    <Frame>
      <S.Container>
        <S.TableContainer>
          {list?.map((item, i) => {
            return (
              <S.CompanyItem key={i}>
                <S.CompanyImage src="https://i.imgur.com/RpO4IrB.png" />
                <span>{item.label}</span>
                <div></div>
                <a href={`/metas/${item.id}`}>Editar</a>
              </S.CompanyItem>
            )
          })}
        </S.TableContainer>
      </S.Container>
    </Frame>
  )
}

export default Goals
