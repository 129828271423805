import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 32px;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    > span {
      color: var(--dark-gray);
    }

    > button:first-child,
    > button:last-child {
      margin: 0 16px;
      color: var(--dark-gray);
      cursor: pointer;
    }
  }
`

interface ButtonProps {
  active?: boolean
}

export const Button = styled.div<ButtonProps>`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background: ${props => (props.active ? 'var(--primary)' : 'var(--white)')};
  color: ${props => (props.active ? 'var(--white)' : 'var(--dark-gray)')};
  box-shadow: var(--box-shadow);
  font-size: 16px;
`
