import { Circles } from '@agney/react-loading'
import React from 'react'

import { ButtonContainer } from './styles'

export interface ButtonProps {
  colorScheme?: number
  fullWidth?: boolean
  onClick?: any
  disabled?: boolean
  className?: string
  isLoading?: boolean
  icon?: any
  style?: any
}

// Color scheme:
// 0 - Primary / white bg
// 1 - Secondary / white bg
// 2 - White / primary bg

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <ButtonContainer {...props}>
      {props.isLoading && (
        <div>
          <Circles />
        </div>
      )}
      {props.icon}
      {children}
    </ButtonContainer>
  )
}

export default Button
