import React from 'react'
// import { Helmet } from 'react-helmet'
import { GeneralProvider } from './contexts/GeneralContext'
import ApplicationRoutes from './Routes'

import './styles/FontImport.css'
import GlobalStyles from './styles/GlobalStyles'
import { QueryClient, QueryClientProvider } from 'react-query'
import { UserProvider } from './contexts/UserContext'

const queryClient = new QueryClient()

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <GeneralProvider>
        <UserProvider>
          <ApplicationRoutes />
        </UserProvider>
      </GeneralProvider>
    </QueryClientProvider>
  )
}

export default App
