import React from 'react'

import { Routes, Route, Navigate } from 'react-router-dom'
import Admins from './components/Pages/Admins'
import CompanyCreate from './components/Pages/Companies/Create'
import Goals from './components/Pages/Goals'
import NotFound from './components/Pages/NotFound'
import Signin from './components/Pages/Signin'
import Users from './components/Pages/Users'
import { useUser } from './contexts/UserContext'

const RoutesComponent: React.FC = () => {
  const { user } = useUser()

  return (
    <Routes>
      <Route
        path="/"
        element={
          user ? <Users /> : <Signin />
        }
      />
      <Route
        path="/users"
        element={user ? <Navigate to="/" replace /> : <Signin />}
      />

      <Route
        path="/metas"
        element={user ? <Goals /> : <Signin />}
      />

      <Route
        path="/metas/:id"
        element={user ? <CompanyCreate /> : <Signin />}
      />

      <Route path="/admins" element={user ? <Admins /> : <Signin />} />

      <Route path="/auth" element={user ? <Navigate to="/" /> : <Signin />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default RoutesComponent
