import React, { useState } from 'react'

import * as S from './styles'
import {ReactComponent as Logo} from '../../../../assets/images/Logotipo.svg'
import Button from '../../../Button'

import Image from '../../../../assets/images/signin_banner.png'
import { useUser } from '../../../../contexts/UserContext'
import Input from '../../../Input'

const ModalSignIn: React.FC = () => {
  const { signIn } = useUser()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSignin = () => {
    signIn({ email, password })
  }

  return (
    <S.Container>
      {/* <S.CloseModal onClick={() => setModalSignin(false)} /> */}
      <S.ModalCard>
        <S.ImageCard>
          <img src={Image} alt="background"/>
          <S.LogoContainer>
            <Logo />
          </S.LogoContainer>
        </S.ImageCard>
        <S.Form>
          <h1>Bem vindo, mentor!</h1>
          <h3>Cadastre, edite e exclua perguntas e novos administradores.</h3>
          <Input
            label="E-mail"
            placeholder="Digite seu e-mail..."
            onChange={setEmail}
            value={email}
          />
          <Input
            label="Senha"
            placeholder="Digite sua senha..."
            onChange={setPassword}
            value={password}
            isPassword
            onEnter={handleSignin}
          />

          <Button
            colorScheme={2}
            fullWidth
            disabled={!email || !password}
            onClick={handleSignin}
          >
            Entrar
          </Button>
        </S.Form>
      </S.ModalCard>
    </S.Container>
  )
}

export default ModalSignIn
