import React, { useEffect, useRef } from 'react'
import { IoClose } from 'react-icons/io5'
import * as S from './styles'
import { useGeneral } from '../../../contexts/GeneralContext'
import { useNavigate } from 'react-router-dom'
import InputModal from '../InputModal'

interface ShowUserProps {
  data?: any
  setShowUserModal?: any
}

const ShowUser: React.FC<ShowUserProps> = ({ data, setShowUserModal }) => {
  const { setIsLoading, showAlert, alertError } = useGeneral()
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <S.Container>
      <div onClick={() => setShowUserModal(undefined)}></div>
      <S.Content>
        <S.Header>
          <h1>{data?.name}</h1>
          <button onClick={() => setShowUserModal(undefined)}>
            <IoClose size={24} />
          </button>
        </S.Header>
        <S.Body>
          <InputModal label="Idade" value={data?.age} />
          <InputModal label="Escolaridade" value={data?.schooling} />
          <InputModal label="Estrelas" value={data?.stars || '0'} />
          <InputModal
            label="Metas finalizadas"
            value={data?.goals_finished?.length || '0'}
          />
          <InputModal
            label="Questões respondidas"
            value={data?.questions_answered?.length || '0'}
          />
          <InputModal
            label="Acertos"
            value={
              data?.questions_answered?.filter((e: any) => e.isCorrect === true)
                ?.length || '0'
            }
          />
          {/* <S.Divisor /> */}
          {/* <div>
            <S.Button
              secondary
              onClick={() => {
                handleChangeStatus(
                  4,
                  showFinish?.code,
                  navigate,
                  setIsLoading,
                  showAlert,
                  alertError,
                  showFinish?.callback
                )
                setShowFinish(undefined)
              }}
            >
              Finish order
            </S.Button>
            <S.Button
              onClick={() => {
                setShowFinish(undefined)
              }}
            >
              Keep order opened
            </S.Button>
          </div> */}
        </S.Body>
      </S.Content>
    </S.Container>
  )
}

export default ShowUser
