import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useGeneral } from '../../../contexts/GeneralContext'
import Button from '../../Button'
import { formmatSlug, getPage, getSlug, getUrlFromSlug } from '../statics'

import * as S from './styles'

interface HeaderProps {
  hasCtaAction?: any
}

const Header: React.FC<HeaderProps> = props => {
  const location = useLocation()
  const {
    headerTitle,
    setHeaderTitle,
    isLoading,
    setHeaderReady,
    headerFunction,
    ctaTitle,
    setCtaTitle,
    setModalAdminShow
  } = useGeneral()

  const page = getPage(location.pathname)

  useEffect(() => {
    setHeaderReady(false)
    props.hasCtaAction(!!page?.cta)
    setHeaderTitle(page?.label)
    if (page?.cta?.title) {
      setCtaTitle(page?.cta?.title)
    }
    setHeaderReady(true)
  }, [])

  return isLoading ? null : (
    <S.Container>
      <S.Title>{headerTitle}</S.Title>
      {page?.cta && headerFunction !== 'false' && (
        <Button
          colorScheme={2}
          onClick={() => {
            page?.cta?.action(setModalAdminShow)
          }}
        >
          {ctaTitle}
        </Button>
      )}
    </S.Container>
  )
}

export default Header
