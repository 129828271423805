import React, { useState } from 'react'

import * as S from './styles'

import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'

interface InputProps {
  label: string
  placeholder?: string
  isTextArea?: boolean
  onChange?: any
  value?: string
  isPassword?: boolean
  readonly?: boolean
  onEnter?: any
}

const Input: React.FC<InputProps> = props => {
  const [visible, setVisible] = useState(false)

  return (
    <S.Container>
      <S.Label>{props.label}</S.Label>
      {props.isTextArea ? (
        <S.TextArea
          readOnly={props.readonly}
          placeholder={props.placeholder}
          onChange={(e: any) => props.onChange(e.target.value)}
          value={props.value}
          onKeyDown={(e) => (props.onEnter && e.key === 'Enter') && props.onEnter()}
        />
      ) : (
        <S.InputElement
          readOnly={props.readonly}
          placeholder={props.placeholder}
          onChange={(e: any) => props.onChange(e.target.value)}
          value={props.value}
          type={visible || !props.isPassword ? 'text' : 'password'}
          onKeyDown={(e) => (props.onEnter && e.key === 'Enter') && props.onEnter()}
        />
      )}
      {props.isPassword && (
        <S.HideButton onClick={() => setVisible(!visible)}>
          {!visible ? (
            <AiOutlineEye size={22} />
          ) : (
            <AiOutlineEyeInvisible size={22} />
          )}
        </S.HideButton>
      )}
    </S.Container>
  )
}

export default Input
