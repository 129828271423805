import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100000;
  padding: 24px;

  > div:first-child {
    position: absolute;
    inset: 0;
    z-index: 0;
  }
`

export const Content = styled.div`
  z-index: 10001;
  display: flex;
  flex-direction: column;
  padding: 24px 26px;
  background: var(--white);
  border-radius: 8px;
  width: 100%;
  max-width: 580px;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--border);
  color: var(--dark-2);

  > h1 {
    font-size: 24px;
  }

  > button {
    color: var(--dark-2);
    transition: 0.2s ease;
    cursor: pointer;

    :hover {
      color: var(--dark-1);
    }
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  align-items: center;
  gap: 16px;

  > span:nth-child(2) {
    font-size: 12px;
    color: var(--dark-2);
  }
`

export const Divisor = styled.div`
  width: 100%;
  height: 1px;
  background: var(--medium-gray);
  margin: 16px 0;
`

interface ButtonProps {
  secondary?: boolean
}

export const Button = styled.button<ButtonProps>`
  text-align: center;
  width: 100%;
  padding: 12px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  color: var(--white);
  background: var(--${props => (props.secondary ? 'dark-gray' : 'primary')});
  cursor: pointer;
`
