import React, { useContext, useState, createContext, useEffect } from 'react'
import AlertBallon from '../components/AlertBallon'
import Loading from '../components/Loading'
import CreateAdmin from '../components/Modals/CreateAdmin'
import DeleteQuestion from '../components/Modals/DeleteQuestion'
import ShowUser from '../components/Modals/ShowUser'

interface ContextProps {
  showAlert: (
    type: string,
    title: string,
    content: string,
    percentage?: number
  ) => void
  isLoading: boolean
  setIsLoading: any
  alertError: any
  headerTitle: string
  setHeaderTitle: any
  handleChangeTitle: (p: string, v: string) => void
  headerReady: boolean
  setHeaderReady: any
  slugPath: string[]
  setSlugPath: any
  handleChangeSlug: (p: string, v: string, p2?: string, v2?: string) => void
  headerFunction: any
  setHeaderFunction: any
  ctaTitle: string
  setCtaTitle: any
  handleChangeCta: (p: string, v: string) => void
  modalAdminShow: boolean
  setModalAdminShow: React.Dispatch<React.SetStateAction<boolean>>
  showUserModal: any
  setShowUserModal: React.Dispatch<React.SetStateAction<any>>
  showDeleteQuestion: string | undefined
  setShowDeleteQuestion: React.Dispatch<
    React.SetStateAction<string | undefined>
  >
} 

export const GeneralContext = createContext<ContextProps>({} as ContextProps)

export const GeneralProvider: React.FC = ({ children }: any) => {
  const [alertBaloonData, setAlertbaloonData] = useState({
    opened: false,
    type: 'error',
    title: 'Ocorreu um erro!',
    content: 'Tente novamente mais tarde.',
    progressPercentage: 0
  })

  const [showUserModal, setShowUserModal] = useState()
  const [showDeleteQuestion, setShowDeleteQuestion] = useState<string>()

  const [isLoading, setIsLoading] = useState(false)

  const [modalAdminShow, setModalAdminShow] = useState(false)

  const [headerFunction, setHeaderFunction] = useState()
  const [headerReady, setHeaderReady] = useState(false)

  const [headerTitle, setHeaderTitle] = useState('')
  const [ctaTitle, setCtaTitle] = useState('')

  const handleChangeTitle = (parameter: string, value: string) => {
    const newTitle = headerTitle.replace(':' + parameter, value)
    setHeaderTitle(newTitle)
  }

  const handleChangeCta = (parameter: string, value: string) => {
    const newTitle = ctaTitle.replace(':' + parameter, value)
    setCtaTitle(newTitle)
  }

  const [slugPath, setSlugPath] = useState<string[]>([])

  const handleChangeSlug = (
    parameter: string,
    value: string,
    p2?: string,
    v2?: string
  ) => {
    const index = slugPath.findIndex(e => e === ':' + parameter)
    const newSlugPath = [...slugPath]
    newSlugPath[index] = value
    if (p2 && v2) {
      const i2 = slugPath.findIndex(e => e === ':' + p2)
      newSlugPath[i2] = v2
    }
    setSlugPath(newSlugPath)
  }

  const closeAlert = (obj?: any) => {
    const data = { ...obj } || { ...alertBaloonData }
    data.opened = false
    setAlertbaloonData(data)
  }

  const showAlert = (
    type: string,
    title: string,
    content: string,
    percentage?: number
  ) => {
    const data = {
      opened: true,
      type,
      title,
      content,
      progressPercentage: percentage || 0
    }
    setAlertbaloonData(data)
    setTimeout(() => closeAlert(data), 3000)
  }

  const alertError = (e: any) => {
    showAlert(
      'error',
      'Error',
      e?.response?.data?.message ||
        'We had internal problems trying to perform this operation. Please try again in a moment.'
    )
  }

  useEffect(() => {
    const closeAllModals = (e: any) => {
      if (e.keyCode === 27) {
        // setShowModal(false)
      }
    }
    window.addEventListener('keydown', closeAllModals)
    return () => window.removeEventListener('keydown', closeAllModals)
  }, [])

  console.log(showUserModal)

  return (
    <GeneralContext.Provider
      value={{
        showAlert,
        isLoading,
        setIsLoading,
        alertError,
        headerTitle,
        setHeaderTitle,
        handleChangeTitle,
        headerReady,
        setHeaderReady,
        slugPath,
        setSlugPath,
        handleChangeSlug,
        headerFunction,
        setHeaderFunction,
        ctaTitle,
        setCtaTitle,
        handleChangeCta,
        modalAdminShow,
        setModalAdminShow,
        showUserModal,
        setShowUserModal,
        showDeleteQuestion,
        setShowDeleteQuestion
      }}
    >
      <AlertBallon {...alertBaloonData} closeFunction={closeAlert} />
      {children}
      {showUserModal && (
        <ShowUser data={showUserModal} setShowUserModal={setShowUserModal} />
      )}
      {showDeleteQuestion && (
        <DeleteQuestion
          data={showDeleteQuestion}
          setDeleteQuestion={setShowDeleteQuestion}
        />
      )}
      {modalAdminShow && (
        <CreateAdmin data={modalAdminShow} setCreateAdmin={setModalAdminShow} />
      )}
      {isLoading && <Loading />}
    </GeneralContext.Provider>
  )
}

export const useGeneral = (): ContextProps => useContext(GeneralContext)
