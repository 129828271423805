import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow-y: auto;
  /* background: rgba(0, 0, 0, 0.8); */
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 1000;

  @media (max-width: 600px) {
    align-items: center;
  }
`

export const CloseModal = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
`

export const ModalCard = styled.div`
  display: flex;
  background: var(--white);
  width: 660px;
  margin: 64px 32px;
  border-radius: 15px;
  overflow: hidden;
  z-index: 1002;
  box-shadow: 0px 5px 15px 15px rgba(0, 0, 0, 0.05);

  @media (max-width: 980px) {
    flex-direction: column;
  }
`

export const ImageCard = styled.div`
  display: flex;
  position: relative;
  width: 33%;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 980px) {
    width: 100%;
    height: 88px;

    > img {
      display: none;
    }
  }
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 24px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(47, 135, 150);
  background: linear-gradient(0deg, #0167af 0%, #0167af00 100%);

  > svg {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 980px) {
    background: #0167af;
  }
`

export const Form = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px 26px;
  position: relative;

  > h1 {
    font-size: 22px;
    font-weight: bold;
    color: var(--black);
  }

  > h3 {
    font-size: 13px;
    font-weight: 400;
    color: var(--dark-gray);
    margin-bottom: 24px;
  }

  > button {
    margin-top: 24px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 26px;
  color: var(--dark-gray);
  cursor: pointer;
`

export const DivisorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  > div {
    flex: 1;
    height: 1px;
    background: var(--medium-gray);
  }

  > span {
    font-size: 13px;
    color: var(--dark-gray);
    margin: 0 16px;
  }
`
