import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  z-index: 9999;

  > div {
    width: 65px;
    height: 65px;
  }
`
