import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    color: var(--50);
  }
  html, border-style, #root{
    max-height: 100vh;
    max-width: 100vw;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  body {
    overflow-x: hidden;
    background-color: var(--90);
  }
  *, button, input{
    border: 0;
    background: none;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
  a {
    text-decoration: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  :root{
    --primary: #60CEFF;
    --secondary: #A75D3D;
    --cream: #F4F0ED;
    --light-gray: #F1F1F1;
    --medium-gray: #DDDDDD;
    --dark-gray: #888888;
    --black: #03032B;
    --background: #FAFAFA;
    --white: #FFFFFF;
    --shadow: 0px 5px 15px 0px rgba(138,149,158, 0.15);
    --box-shadow: 0px 5px 15px 0px rgba(138,149,158, 0.15);
    --box-shadow-right: 5px 0px 15px 0px rgba(138,149,158, 0.15);
    --primary-rgb: 47, 135, 150;
    --secondary-rgb: 167, 93, 61;
  }
`
