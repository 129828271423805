import React, { useState } from 'react'

import * as S from './styles'

import { useGeneral } from '../../contexts/GeneralContext'
import Loading from '../Loading'

import { FiLogOut } from 'react-icons/fi'
import { iconSize, menuItems } from './statics'
import { ReactComponent as Logo } from '../../assets/images/logo_left_menu.svg'
import { ReactComponent as LogoResponsive } from '../../assets/images/logo_responsive_menu.svg'
import { useLocation } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import Header from './Header'
import { useUser } from '../../contexts/UserContext'

interface FrameProps {
  children?: any
  style?: any
}

const Frame: React.FC<FrameProps> = props => {
  const { isLoading } = useGeneral()
  const { logout, user } = useUser()
  const [hasCta, setHasCta] = useState(false)
  const location = useLocation()

  const n = location.pathname.indexOf('?')
  const path = location.pathname.substring(
    0,
    n !== -1 ? n : location.pathname.length
  )

  return isLoading ? (
    <Loading />
  ) : (
    <S.Container style={props.style}>
      <S.LeftMenu>
        <S.LogoContainer>
          <a href="/">
            <Logo />
          </a>
        </S.LogoContainer>
        <S.ItemsContainer isAdmin={user.role === 'ADMIN'}>
          {menuItems.map((item: any, i: number) => (
            <Tooltip title={item.label} key={i}>
              <S.MenuItem
                href={item.url}
                active={'/' + path.split('/')[1] === item.url}
              >
                {item.icon}
              </S.MenuItem>
            </Tooltip>
          ))}
        </S.ItemsContainer>
        <S.ProfileContainer>
          <Tooltip title="Logout">
            <S.MenuItem onClick={logout}>
              <FiLogOut size={iconSize} />
            </S.MenuItem>
          </Tooltip>
        </S.ProfileContainer>
      </S.LeftMenu>
      <S.Content>
        <S.Wrapper hasCta={hasCta}>
          <Header hasCtaAction={setHasCta} />
          {props.children}
        </S.Wrapper>
      </S.Content>
      <S.ResponsiveMenu>
        <LogoResponsive />
        <div>
          <S.MenuItem>
            <FiLogOut size={iconSize} />
          </S.MenuItem>
        </div>
      </S.ResponsiveMenu>
    </S.Container>
  )
}

export default Frame
