import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGeneral } from '../../../../contexts/GeneralContext'
import { getUniqueDocument } from '../../../../lib/firebase'
import Frame from '../../../Frame'
import Step2 from './Step2'

import * as S from './styles'

interface CreateCompanyProps {
  isEdit?: boolean
}

const Create: React.FC<CreateCompanyProps> = ({ isEdit }) => {
  const {
    setIsLoading,
    alertError,
    handleChangeTitle,
    headerReady,
    setHeaderReady
  } = useGeneral()
  const navigate = useNavigate()
  const [goal, setGoal] = useState<any>()

  const params = useParams()
  const goalId = params.id

  const handleFillData = async () => {
    setIsLoading(true)
    try {
      const response = await getUniqueDocument('goals', goalId + '')
      setGoal(response)
    } catch (error) {
      alertError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    handleFillData()
  }, [])

  useEffect(() => {
    if (goal && headerReady) {
      handleChangeTitle('goalName', goal?.label + '')
      setHeaderReady(false)
    }
  }, [headerReady, goal])

  return (
    <Frame>
      <button style={{marginRight: 'auto',color: 'var(--dark-gray)', cursor: 'pointer'}} onClick={() => navigate('/metas')}>Voltar</button>
      <S.Container>
        <Step2 goalId={goalId + ''} />
      </S.Container>
    </Frame>
  )
}

export default Create
