import React from 'react'
import ModalSignIn from './ModalSignIn'
import Bg from '../../../assets/images/background_login.png'

import * as S from './styles'

const Signin: React.FC = () => {
  return (
    <S.Container>
      <img
        src={Bg}
        alt=""
        style={{
          flex: 1,
          objectFit: 'contain'
        }}
      />
      <ModalSignIn />
    </S.Container>
  )
}

export default Signin
