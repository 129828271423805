import styled from 'styled-components'
import { ButtonProps } from '.'

export const colorGenerator = (color: number): any => {
  switch (color) {
    case 0:
      return {
        text: 'var(--primary)',
        bg: 'var(--white)'
      }
    case 1:
      return {
        text: 'var(--secondary)',
        bg: 'var(--white)'
      }
    case 2:
      return {
        text: 'var(--white)',
        bg: 'var(--primary)'
      }
    case 3:
      return {
        text: 'var(--primary)',
        bg: 'var(--background)'
      }
  }
}

export const ButtonContainer = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 36px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
  transition: 0.2s ease;
  cursor: pointer;
  width: ${props => (props.fullWidth ? '100%' : 'fit-content')};
  color: ${props => colorGenerator(props.colorScheme || 0)?.text};
  background: ${props => colorGenerator(props.colorScheme || 0)?.bg};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  &:hover {
    filter: brightness(0.95);
  }

  &:active {
    background: ${props => colorGenerator(props.colorScheme || 0)?.bg};
  }

  > svg {
    margin-right: 12px;
  }

  > div {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }
`
