import React, { useRef } from 'react'
import Input from '../../../../../Input'

import * as C from './styles'
import * as S from '../../styles'
import Button from '../../../../../Button'
import { FaSave } from 'react-icons/fa'

interface FormProps {
  data: any
  setData: any
  isEdit?: boolean
  finish?: any
}

const Form: React.FC<FormProps> = ({ data, setData, isEdit, finish }) => {
  const findOriginalIndex = (i: number) => {
    const obj = data.answers.filter((e: any) => e.isCorrect !== true)[i]
    return data.answers.findIndex((e:any) => e.text === obj.text)
  }

  const correctIndex = !isEdit ? 0 : data.answers.findIndex((e: any) => e.isCorrect === true)
  const answer2 = useRef(!isEdit ? 1 : findOriginalIndex(0))
  const answer3 = useRef(!isEdit ? 2 : findOriginalIndex(1))
  const answer4 = useRef(!isEdit ? 3 : findOriginalIndex(2))

  const handleChangeAnswer = (answerIndex: number, e: string) => {
    const newData = { ...data }
    const newAnswers = []
    for (let index = 0; index < 4; index++) {
      if(index === answerIndex){
        newAnswers.push({
          text: e,
          isCorrect: false
        })
      } else {
        newAnswers.push(newData.answers[index])
      }
    }
    newData.answers = newAnswers
    setData(newData)
  }

  return (
    <C.Container>
      <h3>{isEdit ? 'Editar pergunta' : 'Criar nova pergunta'}</h3>
      <S.Description style={{ marginBottom: 16 }}>
        Insira os dados para cadastrar uma nova pergunta
      </S.Description>
      <Input
        label="Pergunta"
        placeholder="Digite a pergunta..."
        value={data.text}
        onChange={(e: string) => {
          const newData = { ...data }
          newData.text = e
          setData(newData)
        }}
      />
      <Input
        label="Resposta certa"
        placeholder="Digite a resposta certa..."
        value={data.answers[correctIndex].text}
        onChange={(e: string) => {
          const newData = { ...data }
          const newAnswers = []

          for (let index = 0; index < 4; index++) {
            if(index === correctIndex){
              newAnswers.push({
                text: e,
                isCorrect: true
              })
            } else {
              newAnswers.push(newData.answers[index])
            }
          }
          newData.answers = newAnswers
          setData(newData)
        }}
      />
      <Input
        label="Resposta 2"
        placeholder="Digite a resposta 2..."
        value={data.answers[answer2.current].text}
        onChange={(e: string) => {
          handleChangeAnswer(answer2.current, e)
        }}
      />
      <Input
        label="Resposta 3"
        placeholder="Digite a resposta 3..."
        value={data.answers[answer3.current].text}
        onChange={(e: string) => {
          handleChangeAnswer(answer3.current, e)
        }}
      />
      <Input
        label="Resposta 4"
        placeholder="Digite a resposta 4..."
        value={data.answers[answer4.current].text}
        onChange={(e: string) => {
          handleChangeAnswer(answer4.current, e)
        }}
      />
      <Input
        label="Feedback"
        placeholder="Explique a resposta correta..."
        value={data.feedback}
        onChange={(e: string) => {
          const newData = { ...data }
          newData.feedback = e
          setData(newData)
        }}
      />
      <Input
        label="Referência"
        placeholder="Digite a referência da pergunta..."
        value={data.reference}
        onChange={(e: string) => {
          const newData = { ...data }
          newData.reference = e
          setData(newData)
        }}
      />
      {isEdit && (
        <Button
          colorScheme={2}
          icon={<FaSave size={18} />}
          fullWidth
          disabled={
            !data.text ||
            !data.reference ||
            !data.feedback ||
            !data.answers[0].text ||
            !data.answers[1].text ||
            !data.answers[2].text ||
            !data.answers[3].text
          }
          onClick={finish}
        >
          Finalizar
        </Button>
      )}
    </C.Container>
  )
}

export default Form
