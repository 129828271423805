import React, { useContext, useState, createContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useGeneral } from './GeneralContext'
import { useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword, signOut, User } from 'firebase/auth'
import { auth } from '../lib/firebase'

export interface SignInProps {
  email: string
  password: string
}

interface ContextProps {
  user?: any
  validToken: boolean
  authenticated: boolean
  logout: () => void
  signIn: (data: SignInProps) => Promise<void>
}

export const UserContext = createContext<ContextProps>({} as ContextProps)

export const UserProvider: React.FC = ({ children }: any) => {
  const { setIsLoading, showAlert } = useGeneral()
  const navigate = useNavigate()

  const [user, setUser] = useState<User | undefined>()
  const [validToken, setValidToken] = useState(false)

  const signIn = async (data: SignInProps) => {
    setIsLoading(true)
    try {
      const response = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      )
      setUser(response.user)
      Cookies.set('Pediatrix@user', JSON.stringify(response.user))
    } catch (e: any) {
      showAlert(
        'error',
        'Falha ao fazer login',
        e?.response?.data?.message ||
          'Tivemos problemas internos ao tentar fazer login, tente novamente em instantes.'
      )
      setIsLoading(false)
    }
  }

  const logout = async () => {
    Cookies.remove('Pediatrix@user')
    await signOut(auth)
    setUser(undefined)
    navigate('/auth')
  }

  useEffect(() => {
    async function loadUserFromCookies() {
      setIsLoading(true)
      const token = Cookies.get('Pediatrix@user')
      if (token !== undefined && token !== 'undefined') {
        setUser(JSON.parse(token))
        setValidToken(true)
      } else {
        setValidToken(false)
      }
      setIsLoading(false)
    }
    loadUserFromCookies()
  }, [])

  return (
    <UserContext.Provider
      value={{
        user,
        validToken,
        authenticated: !!user,
        logout,
        signIn
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = (): ContextProps => useContext(UserContext)
