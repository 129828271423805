import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px !important;
  width: 100%;
`

export const Label = styled.span`
    font-weight: bold;
    color: var(--dark-gray);
    font-size: 14px;
`

export const Input = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 8px 12px;
  background: var(--light-gray);
  border-radius: 6px;
  color: var(--dark-gray);
`
