import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
  margin-bottom: 16px;
  position: relative;
`

export const Label = styled.span`
  font-size: 13px;
  font-weight: bold;
  color: var(--dark-gray);
`

export const TextArea = styled.textarea`
  display: flex;
  width: 100%;
  min-height: 112px;
  padding: 12px 20px;
  background: var(--background);
  border-radius: 5px;
  font-size: 13px;
  color: var(--black);
  resize: none;

  ::placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder {
    color: var(--dark-gray);
  }

  :focus,
  :active {
    outline-color: rgba(var(--primary-rgb), 0.8);
    outline-width: 1.8px;
    outline-style: solid;
  }
`

export const InputElement = styled.input`
  display: flex;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  background: var(--background);
  border-radius: 5px;
  font-size: 13px;
  color: var(--black);

  ::placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder {
    color: var(--dark-gray);
  }

  :focus,
  :active {
    outline-color: rgba(var(--primary-rgb), 0.8);
    outline-width: 1.8px;
    outline-style: solid;
  }
`

export const HideButton = styled.button`
  color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 32px;
  right: 16px;
  cursor: pointer;
`
